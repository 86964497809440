$font-family-base: "Playfair Display", serif;
$headings-font-family: "Playfair Display", serif;
$font-family-lg: "Playfair Display", serif;

$theme-colors: (
    "primary": #bd5a80,
    "light": #fffbf5,
);

$footer-bg: map-get($theme-colors, "light");

$btn-border-radius: 0;
$btn-border-radius-lg: 0;
