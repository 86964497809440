@import "local_variables";

@import "~regulus-oscar/regulus_oscar/static_src/regulus_oscar/scss/main";
@import "~aos/dist/aos.css";

body {
    background: #eee0c9;
}

// Used on checkout header
.header-brand {
    text-align: left;
}

.header-logo {
    max-height: 40px;
}

.page {
    overflow-x: hidden;
}

.container-escape {
    width: 100vw;
    margin-left: 50%;
    transform: translateX(-50%) !important;
}

.navbar-collapse {
    flex-grow: 0;
}

.navbar-nav {
    @include media-breakpoint-down(sm) {
        margin: 0.5em -1em 0;
        background: rgba(255,255,255,0.8);
    }
}

.navbar-light .navbar-toggler {
    border: none;
}

.header-main {
    background: theme-color('light');
    margin-bottom: 2em;

    @include media-breakpoint-down(sm) {
        padding: 0;
    }

    h1 {
        text-transform: uppercase;
        font-size: 16px;
        font-weight: bold;
        margin: 0;
    }

    h1, .nav-item {
        letter-spacing: 1.5px;
    }
}

.header-basket-quantity {
    top: -5px;
    right: -7px;
}

.navbar-light .navbar-nav .nav-link {
    text-transform: uppercase;
    font-weight: bold;
    color: theme-color('primary');
}

.navbar-expand-lg .navbar-nav .nav-link {
    padding-left: 1em;
    padding-right: 1em;
}

.content {
    font-size: 18px;
}

.block {
    margin-bottom: 3em;
}

.block-text {
    max-width: 760px;
    text-align: center;
    margin-left: auto;
    margin-right: auto;
    line-height: 1.7;
}

.block-quote {
    padding-top: 2em;
    padding-bottom: 2em;
    border-top: 1px solid rgba(0,0,0,0.1);
    border-bottom: 1px solid rgba(0,0,0,0.1);
    color: theme-color('primary');
    text-align: center;
    font-size: 26px;
    font-style: italic;

    @include media-breakpoint-down(sm) {
        padding-top: 0.5em;
        padding-bottom: 0.5em;
    }

    &:before {
        display: block;
        content: "“";
        font-size: 300%;
        opacity: 0.5;
        line-height: 1;
    }

    .quote__name {
        opacity: 0.7;
        font-size: 80%;
    }
}

.block-quote + .block-quote {
    border-top: none;
    padding-top: 0;
}

.subheading {
    font-weight: 600;
    margin-bottom: 0.5em;

    .block & {
        text-align: center;
        max-width: 760px;
        margin-left: auto;
        margin-right: auto;
        line-height: 1.5;
    }
}

.full-width-image {
    position: relative;
    text-align: center;
    h2 {
        position: absolute;
        display: flex;
        align-items: center;
        justify-content: center;
        height: 100%;
        width: 100%;
        left: auto;
        right: auto;
        top: 0;
        bottom: 0;
        color: #FFF;
        font-size: 77px;
        text-shadow: 0 0 4px #333;
    }

    @include media-breakpoint-down(sm) {
        h2 {
            font-size: 40px;
        }
    }
}

.block-full_width_image:first-child {
    margin-top: -2em;
}

.product-share {
    .btn:not(:hover) {
        border-color: #777 !important;
        .icon {
            color: #777 !important;
        }
    }
    .btn-email {
        display: none;
    }
}


.product-card {
    background: transparent;
    border: none;
    font-size: 18px;
}

.product-card-body {
    padding: 0 0.5em;
}

.product-card-name {
    margin-top: 0.75em;
    font-size: 100%;
}

.product-actions {
    background: rgba(255,255,255,0.7);
}

.product-subsidiary-content {
    margin-top: 2em;
    padding-top: 2em;
    border-top: 1px solid rgba(0,0,0,0.1);
}

.catalogue-review-summary__score-wrap {
    background: rgba(255,255,255,0.5);
}

.page-footer {
    font-size: 18px;
    a {
        text-decoration: underline;
    }
}

audio {
    display: block;
    margin: 0 auto;
    max-width: 100%;
    width: 760px;
}

.press-item {
    margin-bottom: 2em;

    + .press-item {
        padding-top: 2em;
        border-top: 1px solid rgba(0,0,0,0.1);
    }
}

.js-cookie-message {
    position: fixed;
    bottom: 10px;
    left: 10px;
    max-width: 300px;
    z-index: 200;
}

.footer-logo {
    width: 180px;
    @include media-breakpoint-down(md) {
        margin-bottom: 1em;
    }
}
